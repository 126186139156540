import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IUser } from '../../interfaces/authentication/user.interface';
import { CompletarCadastroRequest } from '../../interfaces/completar-cadastro/completar-cadastro-request.interface';
import { IDomeCloudResponse } from '../../interfaces/shared/api-response.interface';
import { ISubsidiaryResponse } from '../../interfaces/user/subsidiary-response.interface';
import { ISubsidiary } from '../../interfaces/user/subsidiary.interface';
import {
    IUserRequest,
    IUserEmailConfigRequest,
    IUserChangePasswordRequest,
    IUserCompleteSubsidiaryResponse,
} from '../../interfaces/user/user-request.interface';
import { IUserResponse } from '../../interfaces/user/user-response.interface';
import { environment } from '../../../environments/environment';
import { IEnum } from '../../interfaces/shared/enum.interface';
import { IEnterpriseData } from '../../interfaces/authentication/enterprise-data.interface';
import { IVertical } from '../../interfaces/authentication/vertical.interface';
import { format, isBefore, differenceInDays } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class UserService {
    public getCertificateMessage = {
        valid: (date: string) => ({
            mensagemCertificado: `Certificado Válido: ${format(new Date(date), 'dd/MM/yyyy')}`,
            iconeCertificado: 'check-circle',
        }),
        expired: () => ({
            mensagemCertificado:
                'O seu certificado digital está expirado, para renovar com a Linx , <a href="https://mkt.linx.com.br/certificado-digital" target="_blank">clique aqui.</a>',
            iconeCertificado: 'close-circle',
        }),
        toExpire: (date: string) => {
            const daysDiff = differenceInDays(new Date(date), new Date());
            return {
                mensagemCertificado: `O seu certificado digital vai expirar em ${daysDiff} dias, para renovar com a Linx , <a href="https://mkt.linx.com.br/certificado-digital" target="_blank">clique aqui.</a>`,
                iconeCertificado: 'exclamation-circle',
            };
        },
    };

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get<IUser[]>(`${environment.baseUrlDome}/Authenticator/Login/usuarioautenticado`);
    }

    getUsers(payload: IUserRequest) {
        return this.http.post<IUserResponse>(`${environment.baseUrlDome}/Usuarios/ConsultaUsuarios`, payload);
    }

    saveUserEmailConfig(payload: IUserEmailConfigRequest): Observable<IDomeCloudResponse> {
        return this.http.post<IDomeCloudResponse>(`${environment.baseUrlDomeCloud}/alterardadosemailempresa`, payload);
    }

    changeUserPassword(payload: IUserChangePasswordRequest): Observable<IDomeCloudResponse> {
        if (payload.newPassword !== payload.confirmNewPassword) {
            throw new Error('As senhas não conferem, verifique os dados e tente novamente');
        }

        return this.http.post<IDomeCloudResponse>(`${environment.baseUrlDome}/Usuarios/AlterarSenha`, { body: { ...payload }, header: {} });
    }

    getCertificateData(subsidiary: ISubsidiary) {
        const dateStr = subsidiary?.dataExpiracaoCertificado?.split(' ')[0];

        if (!subsidiary.dataExpiracaoCertificado || !dateStr) {
            return subsidiary;
        }

        let statusCertificado = '';
        const date = new Date(dateStr);
        const isExpired = isBefore(date, new Date());
        const daysDiff = differenceInDays(date, new Date());
        const dataFormatada = format(date, 'dd/MM/yyyy');

        if (isExpired) {
            statusCertificado = 'expired';
        } else if (daysDiff > 30) {
            statusCertificado = 'valid';
        } else {
            statusCertificado = 'toExpire';
        }

        return {
            statusCertificado,
            dataFormatada,
            ...this.getCertificateMessage[statusCertificado](subsidiary.dataExpiracaoCertificado),
        };
    }

    getSubsidiary(cnpj: string): Observable<IUserCompleteSubsidiaryResponse> {
        return this.http.get<IUserCompleteSubsidiaryResponse>(
            `${environment.baseUrlDomeCloud}/getcompleteemitterregistration?cnpj=${cnpj}`
        );
    }

    getSubsidiaries(): Observable<ISubsidiary[]> {
        return this.http.get<ISubsidiary[]>(`${environment.baseUrlDome}/Usuarios/ConsultaEmpresas`);
    }

    getCnpjRoles(cnpj: string) {
        return this.http.get<string[]>(`${environment.baseUrlDome}/Usuarios/ConsultaRolesCnpj?cnpj=${cnpj}`);
    }

    setDefaultSubsidiary(cnpj: string) {
        return this.http.put<ISubsidiaryResponse>(`${environment.baseUrlDome}/Usuarios/AtualizaSubsidiariaPadrao`, `"${cnpj}"`, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    completeSubsidiarySignUp(payload: CompletarCadastroRequest) {
        return this.http.post<ISubsidiaryResponse>(`${environment.baseUrlDome}/Empresas/CompletarEmpresa`, payload);
    }

    getEnterpriseData(cnpj: string) {
        return this.http.get<IEnterpriseData>(`${environment.baseUrlDome}/empresas/consultaempresa?cnpj=${cnpj}`);
    }

    getSignUpEnterpriseData(cnpj: string) {
        return this.http.get<IEnterpriseData>(`${environment.baseUrlDome}/empresas/consultacompletarempresa?cnpj=${cnpj}`);
    }

    getOptionsCalculation() {
        return this.http.get<IEnum[]>(`${environment.baseUrlDome}/Empresas/ConsultaCalculoBasis`);
    }

    getOptionsTax() {
        return this.http.get<IEnum[]>(`${environment.baseUrlDome}/Empresas/ConsultaRegimeImpostos`);
    }

    getOptionsSimples() {
        return this.http.get<IEnum[]>(`${environment.baseUrlDome}/Empresas/ConsultaSimplesFramework`);
    }

    getOptionsMain() {
        return this.http.get<IEnum[]>(`${environment.baseUrlDome}/Empresas/ConsultaAtividadesPrincipais`);
    }

    getVerticals() {
        return this.http.get<{ registro: IVertical[] }>(`${environment.baseUrlDomeCloud}/verticais`);
    }

    saveEnterpriseData(payload: IEnterpriseData) {
        return this.http.post(`${environment.baseUrlDome}/Empresas/EditarEmpresa`, { header: {}, body: payload });
    }
}
